<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-numeric</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <!-- <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.idTransportador"
                    label="idTransportador"
                    outlined
                  ></v-text-field> -->
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.idTransportador"
                    label="Transportador"
                    :items="compTransportadoresSelect"
                    item-text="nomeFantasia"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-radio-group
                    dense
                    v-model="varPermissoLocal.tipo"
                    row
                    mandatory
                  >
                    <v-radio label="CRT" value="CRT"></v-radio>
                    <v-radio label="MIC" value="MIC"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.paisOrigem"
                    label="País Origem"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.permisso"
                    label="Permisso"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varPermissoLocal.numero"
                    label="Número"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("cadPermissos");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varPermissoLocal: {
  //     nome_permisso: { required },
  //     email: { required, email },
  //     permisso: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadPermissosForm",
    tituloForm: "",
    varPermissoLocal: {},
  }),

  computed: {
    ...mapState(["staPermissoSelect", "staTransportadoresSelect"]),

    compTransportadoresSelect() {
      return this.staTransportadoresSelect;
    },
  },

  watch: {
    staPermissoSelect(permissoNovo) {
      this.metSincronizar(permissoNovo);
    },
  },

  created() {
    this.metSincronizar(this.staPermissoSelect);
    if (this.staPermissoSelect) {
      this.tituloForm = "Editar Permisso";
    } else {
      this.tituloForm = "Novo Permisso";
    }
    this.actTransportadoresSelect(this.$store.state.login.licenca);
    // this.formataDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarPermisso",
      "actResetarPermisso",
      "actCriarPermisso",
      "actEditarPermisso",
      "actResetarErro",
      "actTransportadoresSelect",
    ]),

    metObterTransportador(id) {
      if (id != null && this.staTransportadoresSelect != undefined) {
        const ret = this.staTransportadoresSelect.filter((t) => t.id == id);
        return ret[0].nomeFantasia;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarPermissoEdicao(permisso) {
      this.actSelecionarPermisso({ permisso });
    },

    async salvarPermisso() {
      switch (this.staPermissoSelect) {
        case undefined:
          this.varPermissoLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varPermissoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varPermissoLocal.aliasTransportador = this.metObterTransportador(
            this.varPermissoLocal.idTransportador
          );
          await this.actCriarPermisso({
            permisso: this.varPermissoLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadPermissos.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadPermissos.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Permisso";
            this.$root.snackbar.show({
              type: "success",
              message: "Permisso Criada!",
            });
          }
          break;
        default:
          this.varPermissoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varPermissoLocal.criado = undefined;
          this.varPermissoLocal.criacao = undefined;
          this.varPermissoLocal.modificado = undefined;
          this.varPermissoLocal.aliasTransportador = this.metObterTransportador(
            this.varPermissoLocal.idTransportador
          );
          await this.actEditarPermisso({
            permisso: this.varPermissoLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadPermissos.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadPermissos.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Permisso Salva!",
            });
          }
      }
      //this.resetar();
    },

    metSincronizar(permisso) {
      this.varPermissoLocal = Object.assign({}, permisso || { id: null });
    },
    submit() {
      // this.$v.$touch();
      this.salvarPermisso();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varPermissoLocal.ultima_compra = this.staPermissoSelect.ultima_compra;
      // this.varPermissoLocal.email = this.staPermissoSelect.email;
      // this.varPermissoLocal.permisso = this.staPermissoSelect.permisso;
      // this.varPermissoLocal.senha = this.staPermissoSelect.senha;
      // this.varPermissoLocal.ativo = this.staPermissoSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
